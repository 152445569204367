import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService } from "../core/api.service";
import { IAssetSystemHierarchy } from '../models/IAssetSystemHierarchy';
import { IAssetSystemName } from '../models/IAssetSystemName';
import { IDataOnlyGrid } from '../models/IDataOnlyGrid';
import { IControlCenterHeader } from '../models/IControlCenterHeader';
import { IControlCenter } from "../models/IControlCenter";


@Injectable({
	providedIn: 'root'
})
export class ControlCenterService {

  private _strAssetURL: string = `/api/Asset`;
  private _strAssetSystemURL: string = `/api/FetchAssetSystem`;
  private _strFetchDataOnlyURL: string = `/api/FetchDataOnly`;
  private _strControlCenterURL: string = `/api/ControlCenter`;
  private _strControlCenterHeaderURL: string = `/api/FetchControlCenterHeader`;
  private _strControlCenterExcludeURL: string = `/api/ControlCenter/Exclude`;
  private _strControlCenterUpdateURL: string = `/api/ControlCenter/Update`;
  private _strRunControlCenterHeaderURL: string = `/api/RunControlCenterHeader`;
  private _strRunCkokeSizingURL: string = `/api/RunChokeSize`;
  

	constructor(private apiService: ApiService) { }
  

  public FetchDataOnlySub: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public ControlCenterSub: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);


  getAssets(): Observable<IAssetSystemHierarchy[]> {
    return this.apiService.get(this._strAssetURL);
  }

  getAssetSystem(assetDescription: string): Observable<IAssetSystemName[]> {
    const _strURL = `${this._strAssetSystemURL}?AssetDescription=${assetDescription}`;
    return this.apiService.get(_strURL);
  }

  getFetchDataOnly(assetDescription: string, systemDescription: string): Observable<IDataOnlyGrid[]> {
    const _strURL = `${this._strFetchDataOnlyURL}?fetchBK=nvbp&assetDescription=${assetDescription}&steamDistributionSystemDesc=${systemDescription}`;

    const obs = this.apiService.get(_strURL);
    const sub = obs.subscribe({
      next: (data: IDataOnlyGrid[]) => {
        this.FetchDataOnlySub.next(data);
        sub.unsubscribe();
        this.FetchDataOnlySub = new BehaviorSubject<any[]>(null);
      }
    });  

    return obs;
  }

  getControlCenter(fetchID: string): Observable<IControlCenter[]> {
    const _strURL = `${this._strControlCenterURL}?fetchID=${fetchID}`;
    
    const obs = this.apiService.get(_strURL);
    const sub = obs.subscribe({
      next: (data: IControlCenter[]) => {
        this.ControlCenterSub.next(data);
        sub.unsubscribe();
        this.ControlCenterSub = new BehaviorSubject<any[]>(null);
      }
    });  

    return obs;
  }

  getFetchControlCenterHeader(fetchID: string): Observable<IControlCenterHeader[]> {
    const _strURL = `${this._strControlCenterHeaderURL}?fetchID=${fetchID}`;
    return this.apiService.get(_strURL);
  }

  setExcludeCCRows(params: any, fetchID: string): Observable<any> {
    const _strURL = `${this._strControlCenterExcludeURL}?fetchID=${fetchID}`;
    return this.apiService.put(_strURL, params);
  }

  setUpdateCCRows(params: any, fetchID: string): Observable<any> {
    const _strURL = `${this._strControlCenterUpdateURL}?fetchID=${fetchID}`;
    return this.apiService.put(_strURL, params);
  }

  runControlCenterHeader(numDesiredPressure: number, 
                        numDesiredSteamVolume: number,
                        numMaximumNumberofChokes: number, 
                        numMinimumNumberofChokes: number,
                        fetchID: string): Observable<IControlCenterHeader[]> {
    const _strURL = `${this._strRunControlCenterHeaderURL}?desiredSysPressure=${numDesiredPressure}&steamVolChange=${numDesiredSteamVolume}&maxChokes=${numMaximumNumberofChokes}&minChokeSize=${numMinimumNumberofChokes}&chkPriority=true&fetchID=${fetchID}`;
    return this.apiService.get(_strURL);
  }

  runChokeSize(fetchID: string): Observable<any> {
    const _strURL = `${this._strRunCkokeSizingURL}?fetchID=${fetchID}`;
    return this.apiService.get(_strURL);
  }

}
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AgGridModule } from 'ag-grid-angular';

import { gridControlCenter } from './gridControlCenter.component';
import { gridDataOnly } from './gridDataOnly.component';
import { AgGridCheckboxComponent } from './ag-grid-checkbox/ag-grid-checkbox.component';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { NgxScrollTopModule } from 'ngx-scrolltop';

@NgModule({
  declarations: [
    gridControlCenter,
    gridDataOnly,
    AppComponent,
    AgGridCheckboxComponent
  ],
  entryComponents: [
    AgGridCheckboxComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    NgxSpinnerModule,
    NgxScrollTopModule
  ],
  providers: [NgxSpinnerService],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

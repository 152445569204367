import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SpinnerService } from './spinner.service';



@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private jsonOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }),
  };


  constructor(private http: HttpClient,
              private spinnerService: SpinnerService) { }


  private handleServerError(err: HttpErrorResponse){
    
    if (!err.ok) {

      let errdescription = err.message;

      if (err.status == 404) {       

        if (err.error && err.error.title)
          errdescription = err.error.title;

      }
      
      //Launch Popup 
      //- https://www.npmjs.com/package/ngx-toastr
      //- https://ngx-toastr.vercel.app/
      //- https://codeseven.github.io/toastr/demo.html
      
    }
    
    return throwError(err).toPromise();
  }

  get(path: string): Observable<any> {
    this.spinnerService.show();

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.get(`${environment.hostApiURL}${path}`, httpOptions)
      .pipe(catchError((err: HttpErrorResponse) => {
                return this.handleServerError(err)
              }), 
            finalize(() => { 
              this.spinnerService.hide(); 
            })
      );
  }

  get_excel(path: string): Observable<any> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/vnd.ms-excel',
    //   }),
    // };

    return this.http.get(`${environment.hostApiURL}${path}`, { responseType: 'blob'} )
      .pipe(catchError((err: HttpErrorResponse) => {
        return this.handleServerError(err)
    }))
  }

  post(path: string, body: object = {}): Observable<any> {
    this.spinnerService.show();

    return this.http.post(`${environment.hostApiURL}${path}`, JSON.stringify(body), this.jsonOptions)
      .pipe(catchError((err: HttpErrorResponse) => {
        return this.handleServerError(err)
    }), finalize(() => { this.spinnerService.hide(); }));
  }

  put(path: string, body: any = {}): Observable<any> {
    this.spinnerService.show();

    return this.http.put(`${environment.hostApiURL}${path}`, JSON.stringify(body), this.jsonOptions)
      .pipe(catchError((err: HttpErrorResponse) => {
        return this.handleServerError(err)
    }), finalize(() => { this.spinnerService.hide(); }));
  }

  delete(path: string, body: any = {}): Observable<any> {
    this.spinnerService.show();

    return this.http.delete(`${environment.hostApiURL}${path}`)
      .pipe(catchError((err: HttpErrorResponse) => {
        return this.handleServerError(err)
    }), finalize(() => { this.spinnerService.hide(); }));
  }
}

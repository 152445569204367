import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridCheckboxComponent } from './ag-grid-checkbox/ag-grid-checkbox.component';
import { SelectMultipleControlValueAccessor } from '@angular/forms';
//import { formatNumber } from '@angular/common';

@Component({
    selector: 'gridControlCenter',
    templateUrl: 'gridControlCenter.component.html'
})

export class gridControlCenter {
    @Input() controlCenterGridData: any;
    private gridApi;
    private gridColumnApi;


    protected roundNumber(value): Number {
      try {        
        if (isNaN(value.value) || value.value == "") {
          value.value = '-';  
        }
        else  
          if (value.value != null) {  
            const num = parseFloat(value.value).toFixed(value.colDef.decPlaces);  
            //value.value = formatNumber(parseFloat(num), 'en-US', '1.0-5');
            value.value = num;
          }  
      }  
      catch (exception) {  
        console.error(exception);  
      }  
  
      return value.value;  
    }

    protected emptyText(value): Number {
      try {        
        if (value.value == "") {
          value.value = '-';  
        }        
      }  
      catch (exception) {  
        console.error(exception);  
      }  
  
      return value.value;  
    }

    ragCellClassRules = {
      'rag-red-outer': function (params) {
        return params.value === 'Check MASIP';
      }
    };

    columnDefs = [
        { headerName: 'Exclude', field: 'exclude', sortable: true, filter: true, editable: true, checkboxSelection: true, width: 130 },
        { headerName: 'Well Name', field: 'wellName', sortable: true, filter: true, width: 130 },
        { headerName: 'Latest Qtest Orifice Static Pressure', field: 'latestQtestOrificeStaticPressure', sortable: true, filter: true, width: 130 },
        { headerName: '4 Qtest Average Orifice Static Pressure', field: 'fourQtestAverageOrificeStaticPressure', sortable: true, filter: true, width: 130 },
        { headerName: 'SPM Wellhead Pressure', field: 'spmWellheadPressure', sortable: true, filter: true, width: 130 },
        { headerName: 'User Adjusted Composite Pressure', field: 'userAdjustedCompositePressure', sortable: true, filter: true, editable: true, width: 130, cellStyle: { backgroundColor: '#8bcff9' } },
        { headerName: 'Latest Qtest', field: 'latestQtest', sortable: true, filter: true, width: 130 },
        { headerName: 'User Adjusted Composite Quality', field: 'userAdjustedCompositeQuality', sortable: true, filter: true, editable: true, width: 130, cellStyle: { backgroundColor: '#8bcff9' } },
        { headerName: 'Curr Composite BSPD', field: 'currCompositeBSPD', sortable: true, filter: true, width: 130 },
        { headerName: 'Current Max Pressure', field: 'currentMaxPressure', valueFormatter: this.roundNumber, decPlaces: 0, sortable: true, filter: true, editable: true, width: 130, cellStyle: { backgroundColor: '#8bcff9' } },
        { headerName: 'MASIP', field: 'masip', valueFormatter: this.roundNumber, decPlaces: 0, sortable: true, filter: true, editable: true, width: 130, cellStyle: { backgroundColor: '#8bcff9' } },        
        { headerName: 'Active or Inactive', field: 'activeOrInactive', sortable: true, filter: true, width: 130 },
        { headerName: 'Existing Choke Size (64ths)', field: 'existingChokeSize64ths', sortable: true, filter: true, width: 130 },
        { headerName: 'Existing Choke Type', field: 'existingChokeType', sortable: true, filter: true, width: 130 },
        { headerName: 'Ideal Choke Size', field: 'idealChokeSize', sortable: true, filter: true, width: 130 },
        { headerName: 'Ideal Choke Type', field: 'idealChokeType', sortable: true, filter: true, width: 130 },
        { headerName: 'Composite Heat Compliance % Over / Under', field: 'compositeHeatCompliancePercentOverOrUnder', sortable: true, filter: true, width: 130 },
        { headerName: 'Latent Heat Target', field: 'latentHeatTarget', sortable: true, filter: true, width: 130 },
        { headerName: 'Latent Heat Actual', field: 'latentHeatActual', sortable: true, filter: true, width: 130 },
        { headerName: 'New BSPD', field: 'newBSPD', sortable: true, filter: true, width: 130 },
        { headerName: 'Estimated New Max Pressure', field: 'estimatedNewMaxPressure', valueFormatter: this.roundNumber, decPlaces: 0, sortable: true, filter: true, width: 130 },
        { headerName: 'MASIP Verification', field: 'masipVerification', valueFormatter: this.emptyText, cellClassRules: this.ragCellClassRules, decPlaces: 0, sortable: true, filter: true, width: 130 },
        { headerName: 'Score', field: 'score', sortable: true, filter: true, width: 130 },
        { headerName: 'Score by Priority', field: 'scoreByPriority', sortable: true, filter: true, width: 130 }
    ];

    

    rowClassRules = {      
      // // row style function
      // 'text-uppercase': function (params) {
      //   //debugger;
      //   var score = params.data.score;
      //   return score == "NotCalculated";
      // },
      // // row style expression
      // 'sick-days-breach': 'data.sickDays >= 8',
    };

    rowSelection = 'multiple';

    rowData: any;

    constructor(private http: HttpClient) {
    }

    //Exclusion records selection
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map(node => node.data.wellName);
      return selectedData;
    }

    //All records selection
    getRowData() {
      let allControlCenterData = [];
      this.gridApi.forEachNode(node => allControlCenterData.push(node.data));
      return allControlCenterData;
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }

    //Grid CSV Export
    onBtnExportControlCenter() {
      var params = this.getSelectedRowData();
      if (params.suppressQuotes || params.columnSeparator) {
        alert(
          'NOTE: you are downloading a file with non-standard quotes or separators - it may not render correctly in Excel.'
        );
      }
      this.gridApi.exportDataAsCsv(params);
    }

    ngOnInit() {
    }
}
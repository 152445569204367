import { Component, ErrorHandler, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { IAssetSystemHierarchy } from './models/IAssetSystemHierarchy';
import { IAssetSystemName } from './models/IAssetSystemName';
import { IControlCenterHeader } from './models/IControlCenterHeader';
import { IDataOnlyGrid } from './models/IDataOnlyGrid';
import { gridControlCenter } from './gridControlCenter.component';
import { Observable, throwError, of, BehaviorSubject, Subscription } from 'rxjs';
import { flatMap, concatMap, catchError, retry, map } from 'rxjs/operators';
//import 'rxjs/add/observable/throw';
//import 'rxjs/add/observable/catch';
import { ControlCenterService } from './control-center/control-center.service';
import { disableDebugTools } from '@angular/platform-browser';
import { IControlCenter } from './models/IControlCenter';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  @ViewChild(gridControlCenter, {​​static: false}​​)
  private controlCenterComponent: gridControlCenter;
  title = 'chokeapp';

  hideContent: boolean;
  disableFetch: boolean;

  selectedAsset: string;
  selectedSystem: string;

  //strAssetURL: string;
  strAssetSystemURL: string;
  strControlCenterHeaderURL: string;
  aryAssetNames: Array<String>;
  aryAssetSystemNames: Array<String>;

  numCurrSystemPressure: number;
  numTargetSteamVolume: number;
  numCurrentSteamVolume: number;
  strCurrentChokeArea: string;
  numGoalChokeArea: number;
  numAdjustedChokeArea: number;
  numMaximumNumberofChokes: number;
  numMinimumNumberofChokes: number;
  numDesiredSteamVolume: number;
  numDesiredPressure: number;

  controlCenterGridDataURL: string;
  controlCenterGridData: any;

  strDataOnlyGridURL: string;
  strControlCenterExcludeURL: string;
  strControlCenterUpdateURL: string;
  strRunChokeSizeURL: string;
  dataOnlyGridData: any;
  aryControlCenterExclude: string;
  aryControlCenterUpdate: any;

  _AssetsSub: Subscription;
  _FetchDataOnlySub: Subscription;
  _ControlCenterGridDataSub: Subscription;
  _ControlCenterHeaderDataSub: Subscription;
  _ControlCenterDataSub: Subscription;

  private _FetchID: string;


  constructor(private controlCenterService: ControlCenterService) {
  };

 
  ngOnInit() {
    this.disableFetch = true;
    this.hideContent = true;
    this.aryAssetNames = new Array<String>();

    this._AssetsSub = this.controlCenterService.getAssets().subscribe({
      next: (data: IAssetSystemHierarchy[]) => {
        for (let x of data) {
          if (!this.aryAssetNames.includes(x.assetDescription)) {
            this.aryAssetNames.push(x.assetDescription);
          }
        }
        this.aryAssetNames.sort();
      }
    });
  }

  ngOnDestroy() {
    if (this._AssetsSub) this._AssetsSub.unsubscribe();
    if (this._FetchDataOnlySub) this._FetchDataOnlySub.unsubscribe();
    if (this._ControlCenterGridDataSub) this._ControlCenterGridDataSub.unsubscribe();
    if (this._ControlCenterDataSub) this._ControlCenterDataSub.unsubscribe();
    if (this._ControlCenterHeaderDataSub) this._ControlCenterHeaderDataSub.unsubscribe();
  }

  ngAfterViewInit() {​​
    console.log('Child constructor')
  }​​

  assetSelect(assetValue) {
    this.disableFetch = true;
    this.hideContent = true;
    this.aryAssetSystemNames = new Array<String>();
    this.selectedSystem = "";

    this.controlCenterService.getAssetSystem(assetValue).subscribe({
      next: (data: IAssetSystemName[]) => {
        for (let x of data) {
          if (!this.aryAssetSystemNames.includes(x.steamDistributionSystemDescription)) {
            this.aryAssetSystemNames.push(x.steamDistributionSystemDescription);
          }
        }
        this.aryAssetSystemNames.sort();
      }
    });
  }

  systemSelect(systemValue) {
    if (systemValue !== '') {
      this.disableFetch = false;
    } else {
      this.disableFetch = true;
    }
  }

  //VALIDATE API CODE 200 RETURNED
  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error")
  }

  databaseFetch(form: NgForm) {
    
    //STEP 1 - POPULATE DATA ONLY GRID   //TODO: Get Real CAI---------------------------------
    this._FetchDataOnlySub = this.controlCenterService.FetchDataOnlySub.subscribe({
      next: (data: IDataOnlyGrid[]) => {
        if (this._FetchDataOnlySub) this._FetchDataOnlySub.unsubscribe();
        if (data == null) return;

        this.dataOnlyGridData = data;
      }
    });
    //----------------------------------------------------------------------------------------    

    //STEP 2 - POPULATE CONTROL CENTER GRID---------------------------------------------------
    this.controlCenterGridData = '';

    this._ControlCenterGridDataSub = this.controlCenterService.FetchDataOnlySub.subscribe({
      next: (data: IDataOnlyGrid[]) => {
        if (this._ControlCenterGridDataSub) this._ControlCenterGridDataSub.unsubscribe();
        if (data == null) return;

        this._FetchID = data[0].fetchID;

        this._ControlCenterDataSub = this.controlCenterService.ControlCenterSub.subscribe({
          next: (data: IControlCenter[]) => {
            if (this._ControlCenterDataSub) this._ControlCenterDataSub.unsubscribe();
            this.controlCenterGridData = data;
          }
        });
        
        this.controlCenterService.getControlCenter(this._FetchID);
      }
    });
    //----------------------------------------------------------------------------------------
    
    //STEP 3 - POPULATE CONTROL CENTER HEADER-------------------------------------------------
    this.numCurrSystemPressure = 0;
    this.numTargetSteamVolume = 0;
    this.numCurrentSteamVolume = 0;
    this.strCurrentChokeArea = '';
    this.numGoalChokeArea = 0;
    this.numMaximumNumberofChokes = 0;
    this.numMinimumNumberofChokes = 0;
    this.numDesiredPressure=0;
    this.numDesiredSteamVolume = 0;
    
    this._ControlCenterHeaderDataSub = this.controlCenterService.ControlCenterSub.subscribe({
      next: (data: IControlCenter[]) => {
        if (this._ControlCenterHeaderDataSub) this._ControlCenterHeaderDataSub.unsubscribe();
        if (data == null) return;

        const subCCH = this.controlCenterService.getFetchControlCenterHeader(this._FetchID).subscribe({
          next: (dataCCH: IControlCenterHeader[]) => {
            for (let x of dataCCH) {
              this.numCurrSystemPressure = x.currSystemPressure;
              this.numTargetSteamVolume = x.targetSteamVolume;
              this.numCurrentSteamVolume = x.currentSteamVolume;
              this.strCurrentChokeArea = x.currentChokeArea;
              this.numGoalChokeArea = x.goalChokeArea;
              this.numMaximumNumberofChokes = x.maximumNumberofChokes;
              this.numMinimumNumberofChokes = 0;
            }

            subCCH.unsubscribe();
          }
        });

      }
    });
    //----------------------------------------------------------------------------------------


    //LAUNCH STEP 1,2 AND 3-------------------------------------------------------------------
    this.controlCenterService.getFetchDataOnly(this.selectedAsset, this.selectedSystem);
    //----------------------------------------------------------------------------------------


    //SHOW PAGE CONTENT
    this.hideContent = false;
  }

  currentValCurrentSystemPressure = "";
  getValCurrentSystemPressure(val)
  {
    console.warn(val)
    this.currentValCurrentSystemPressure=val
  }

  currentValDesiredPressureChangePercentage = "";
  getValDesiredPressureChangePercentage(val) {
    console.warn(val)
    this.currentValDesiredPressureChangePercentage = val
  }

  currentValDesiredPressureChangePSI = "";
  getValDesiredPressureChangePSI(val) {
    console.warn(val)
    this.currentValDesiredPressureChangePSI = val
  }

  runChokeSizing(form: NgForm) {

    //Exclusion records push to API 
    this.aryControlCenterExclude = this.controlCenterComponent.getSelectedRowData();
    const subRCSE = this.controlCenterService.setExcludeCCRows(this.aryControlCenterExclude, this._FetchID).subscribe({
      next: (data: any) => {
        subRCSE.unsubscribe();  

        //Grid Update records pushed to API
        this.aryControlCenterUpdate = this.controlCenterComponent.getRowData();
        const subRCSU = this.controlCenterService.setUpdateCCRows(this.aryControlCenterUpdate, this._FetchID).subscribe({
          next: (data: any) => {
            subRCSU.unsubscribe();

            //Header values pushed to API            
            const subRCCH = this.controlCenterService.runControlCenterHeader(this.numDesiredPressure, 
                                                                            this.numDesiredSteamVolume, 
                                                                            this.numMaximumNumberofChokes,
                                                                            this.numMinimumNumberofChokes, 
                                                                            this._FetchID).subscribe({
              next: (data: IControlCenterHeader[]) => {
                subRCCH.unsubscribe();

                for (let x of data) {
                  this.numCurrSystemPressure = x.currSystemPressure;
                  this.numTargetSteamVolume = x.targetSteamVolume;
                  this.numCurrentSteamVolume = x.currentSteamVolume;
                  this.strCurrentChokeArea = x.currentChokeArea;
                  this.numGoalChokeArea = x.goalChokeArea;
                  this.numMaximumNumberofChokes = x.maximumNumberofChokes;
                };

                const subRCS = this.controlCenterService.runChokeSize(this._FetchID).subscribe({
                  next: (data: IControlCenter[]) => {
                    subRCS.unsubscribe();

                    this.controlCenterGridData = data;
                  }
                });

              }
            });

          }
        });

      }
    });

  }
}

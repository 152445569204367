import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'gridDataOnly',
    templateUrl: 'gridDataOnly.component.html'
})

export class gridDataOnly {
    private gridApi;
    private gridColumnApi;
    @Input() dataOnlyGridData: any;


    protected dateFormatter(value) {
      var dateAsString = value.value;
      var dateParts = dateAsString.split(' ')[0].split('/');
      return `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`;
    }

    columnDefs = [
        { headerName: 'Well Name', field: 'wellName', sortable: true, filter: true, width: 130 },
        { headerName: 'System Name', field: 'systemName', sortable: true, filter: true, width: 130 },
        { headerName: 'Project', field: 'project', sortable: true, filter: true, width: 130 },
        { headerName: 'TO Area', field: 'toArea', sortable: true, filter: true, width: 130 },
        { headerName: 'Injector Status', field: 'injectorStatus', sortable: true, filter: true, width: 130 },
        { headerName: 'Existing Choke Size', field: 'existingChokeSize', sortable: true, filter: true, width: 130 },
        { headerName: 'Existing Choke Type', field: 'existingChokeType', sortable: true, filter: true, width: 130 },
        { headerName: 'Existing Orifice Plate Size', field: 'existingOrificePlateSize', sortable: true, filter: true, width: 130 },
        { headerName: 'Choke Last Change Date', field: 'chokeLastChangeDate', valueFormatter: this.dateFormatter, sortable: true, filter: true, width: 130 },
        { headerName: 'Choke Size Previous', field: 'chokeSizePrev', sortable: true, filter: true, width: 130 },
        { headerName: 'Thermal OP Target', field: 'thermalOpTarget', sortable: true, filter: true, width: 130 },
        { headerName: 'Reservoir Engineering Target Latent Heat', field: 'reservoirEngineeringTargetLatentHeat', sortable: true, filter: true, width: 130 },
        { headerName: 'Reservoir PSIA', field: 'reservoirPsia', sortable: true, filter: true, width: 130 },
        { headerName: 'Steamflood Maturity Category', field: 'steamFloodMaturityCategory', sortable: true, filter: true, width: 130 },
        { headerName: '4-Prior Date', field: 'fourPriorDate', valueFormatter: this.dateFormatter, sortable: true, filter: true, width: 130 },
        { headerName: '4-Prior P1 PSIG', field: 'fourPriorP1Psig', sortable: true, filter: true, width: 130 },
        { headerName: '4-Prior Quality', field: 'fourPriorQuality', sortable: true, filter: true, width: 130 },
        { headerName: '3-Prior Date', field: 'threePriorDate', valueFormatter: this.dateFormatter, sortable: true, filter: true, width: 130 },
        { headerName: '3-Prior P1 PSIG', field: 'threePriorP1Psig', sortable: true, filter: true, width: 130 },
        { headerName: '3-Prior Quality', field: 'threePriorQuality', sortable: true, filter: true, width: 130 },
        { headerName: '2-Prior Date', field: 'twoPriorDate', valueFormatter: this.dateFormatter, sortable: true, filter: true, width: 130 },
        { headerName: '2-Prior P1 PSIG', field: 'twoPriorP1Psig', sortable: true, filter: true, width: 130 },
        { headerName: '2-Prior Steam Quality', field: 'twoPriorQuality', sortable: true, filter: true, width: 130 },
        { headerName: 'SPM Wellhead 30 Days Avg', field: 'spmWellHead30DaysAvg', sortable: true, filter: true, width: 130 },
        { headerName: 'Test Date', field: 'testDate', valueFormatter: this.dateFormatter, sortable: true, filter: true, width: 130 },
        { headerName: 'Qtest P1 PSIG', field: 'qtestP1Psig', sortable: true, filter: true, width: 130 },
        { headerName: 'Orifice DP', field: 'orificeDp', sortable: true, filter: true, width: 130 },
        { headerName: 'Orifice Diameter', field: 'orificeDiameter', sortable: true, filter: true, width: 130 },
        { headerName: 'Choke Size', field: 'chokeSize', sortable: true, filter: true, width: 130 },
        { headerName: 'Choke Type', field: 'chokeType', sortable: true, filter: true, width: 130 },
        { headerName: 'Down Stream Pressure P2', field: 'downStreamPressureP2', sortable: true, filter: true, width: 130 },
        { headerName: 'Steam Quality Last Qtest', field: 'steamQualityLastQtest', sortable: true, filter: true, width: 130 },
        { headerName: 'Q-Test Calculated BSPD', field: 'qtestCalculatedBspd', sortable: true, filter: true, width: 130 },
        { headerName: 'Asset Quality Target', field: 'assetQualityTarget', sortable: true, filter: true, width: 130 },
        { headerName: 'Asset Name', field: 'assetName', sortable: true, filter: true, width: 130 },
        { headerName: 'Default Injector Pressure', field: 'defaultInjectorPressure', sortable: true, filter: true, width: 130 },
        { headerName: 'Pipe Diameter Inches', field: 'pipeDiameterInches', sortable: true, filter: true, width: 130 },
        { headerName: 'Frac Pressure Constrained ', field: 'fracPressureConstrained', sortable: true, filter: true, width: 130 }
    ];

    rowData: any;

    constructor(private http: HttpClient) {}

    ngOnInit() {
        //this.dataOnlyGridData = this.http.get('http://127.0.0.1/DataOnly.json');
  }
  doGridReady(event) {
    console.log("DATA ONLY READY");
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  //Export to CSV
  onBtnExportData() {
    var params = this.dataOnlyGridData;
    if (params.suppressQuotes || params.columnSeparator) {
      alert(
        'NOTE: you are downloading a file with non-standard quotes or separators - it may not render correctly in Excel.'
      );
    }
    this.gridApi.exportDataAsCsv(params);
  }

}

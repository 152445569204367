import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  private _spinnVal : number = 0;
  private _spinnOldVal : number = 0;


  constructor (private spinner: NgxSpinnerService) {}


  private _spin(flag: number): void {
    this._spinnVal += flag;
  }

  private _refreshState(): void {
    if (this._spinnVal <= 0) {
      this.spinner.hide();
      this._spinnVal = 0;
      this._spinnOldVal = 0;
    }
    else if (this._spinnVal > 0) {
      
      if (this._spinnOldVal == 0)
        this.spinner.show();
      
      this._spinnOldVal = this._spinnVal;
    }    
  }

  public show() : void {
    this._spin(1);
    this._refreshState();
  }

  public hide() : void {
    this._spin(-1);
    this._refreshState();
  }

  public reset() : void {
    this._spinnVal = 0;
    this._spinnOldVal = 0;
    this._refreshState();
  }
}
